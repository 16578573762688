// TODO: there are many implicit operations here such as a stream set being tied to a route change
// break apart the logic in to discrete functions and check for any redundant rendering/ticks
// moreover, some of the implicit stuff with routing and streaming could be formalized into mb a custom hook or something on the redux side of things
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { graphql, Link, navigate, PageProps } from 'gatsby';
import { Heading, Flex, Para, Button, Label } from 'workspace-core-ui';
import Layout from '@containers/Layout';
import BodyWrapper from '@components/BodyWrapper';
import { setHeaderType } from '@slices/gameStateSlice';
import useTranslation from '@hooks/useTranslation';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import {
  ProfileQuestionDataBase,
  ProfileQuestionDataConnector,
  ProfileQuestionDataStreamer,
  Content,
  ControlData,
  Route,
} from 'types';
import { logItem } from '@slices/loggingSlice';
import Seo from '@containers/Seo';
import BackgroundImage from '@components/BackgroundImage';
import ControlCenter from '@components/controls/ControlCenter';
import routeMap from '@content/routemap';
import { COUNTABLE_SCREENS, WINDOW_HASH } from '@sharedConstants';
import routeShuffler from '@utils/routeShuffler';
import {
  setCurrentRoute,
  setCurrentStream,
  setRoutes,
} from '@slices/routeSlice';
import { setTotalClaims } from '@slices/levellingSlice';
import gameConfig from '@content/gameconfig';
import useNavigateLog from '@hooks/useNavigateLog';
import getSymbol from '@utils/getSymbol';

interface ProfileContext {
  url: string;
  screenData: ProfileQuestionDataBase<
    Content,
    ControlData<Content[]>,
    ProfileQuestionDataConnector[] | ProfileQuestionDataStreamer[]
  >;
}

const ProfileQuestion = (
  props: PageProps<Queries.ProfileQuestionPageQuery, ProfileContext>,
) => {
  /** stateful array that gets populated with projected next route actions (ie, when you click a control type) */
  const [mappedControlSubmitActions, setMappedControlSubmitActions] = useState<
    Array<() => void | undefined>
  >([]);
  const dispatch = useAppDispatch();
  // at this point our routes simply consist of 'splitter' stream items
  const { pooledRoutes } = useAppSelector(state => state.route);
  const { t, g } = useTranslation();
  const [startTimestamp, setStartTimestamp] = useState<number>();

  const backgroundSymbol = getSymbol(props.data.profilePageBackgroundSymbol);

  const { Controls, Name, Question_Text, Links_To, Type_Of_Node } =
    props.pageContext.screenData;

  // reserved for the control type (not skip)
  const [nextRoutesList, setNextRoutesList] = useState<string[]>([]);
  // if a user skips, we use have a default routeList to go to
  const [defaultRouteList, setDefaultRouteList] = useState<Route[]>(() => {
    const defaultRoutes = routeShuffler({
      allRoutes: routeMap?.[gameConfig?.Stream_Table_Names[0] ?? 0],
      pooledRoutes,
    });
    return defaultRoutes;
  });

  const { url } = props.pageContext;

  useNavigateLog({ questionName: Question_Text.Content_Type });

  useLayoutEffect(() => {
    // record when user officially "sees" the question
    setStartTimestamp(Date.now());
    // this is more for testing
    dispatch(setCurrentRoute({ compareAgainst: url }));
  }, [dispatch, url]);

  useEffect(() => {
    dispatch(setHeaderType({ headerType: 'minimal' }));
  }, [dispatch, url]);

  /** return the next expected route if our next item is another splitter question  */
  const splitterQuestionAction = (
    n: ProfileQuestionDataBase<Content, ControlData<Content[]>>,
  ) => {
    // TODO: we need to infer the route by combining node terms, this can be refactored to a more stable method later
    const { url: nextQuestionUrl = {} } = routeMap.Splitter.find(
      e => e.name === `Splitter~${n.Name}~${n?.Controls?.Control_Type || ''}`,
    );
    return nextQuestionUrl + WINDOW_HASH || `/${WINDOW_HASH}`;
  };

  const streamRoutesAction = (
    newRoutes: Route[] | undefined,
    streamName: string,
  ) => {
    // set the one route list to be now the one we selected
    dispatch(
      setRoutes({
        routes: newRoutes,
      }),
    );

    dispatch(
      setTotalClaims({
        newTotalClaims:
          newRoutes?.filter(e => COUNTABLE_SCREENS.includes(e.typeOfScreen))
            .length || 0,
      }),
    );
    dispatch(
      setCurrentStream({
        streamName,
      }),
    );
  };

  useEffect(() => {
    // we need to shallowly dig into our object, deciding on steps based on the type of link present, we return an array of actions that are indexed against the "possible answer" given
    const getActions = (): Array<(() => void) | undefined> => {
      // our starting node is always question (it must be) and its possible answers are directly correlated to the links_to array. Binary is bound to [0, 1]th indices, and likerts are bound [0, x] indices
      if (Controls?.Possible_Answers.length !== Links_To?.length) {
        throw new Error(
          'Possible answers do not correlate to the links_to array, cannot assign actions without parity',
        );
      }

      // while our data can be recursive, we only check one layer (the content editor must follow these rules)
      return Links_To.map((node, linkIndex) => {
        // ALL nodes in this links array MUST of of type connector, otherwise throw
        if (node.Type_Of_Node === 'Connector') {
          // a connector node is always followed by either another question or a streamer. Return correct action accordingly.
          // a connectors links_to must be an object
          const connectorNodeLink = node.Links_To;
          if (connectorNodeLink.Type_Of_Node === 'Question') {
            setNextRoutesList(e => {
              e[linkIndex] = splitterQuestionAction(connectorNodeLink);
              return e;
            });
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
          }

          // connecting to a stream will just send us to our relevant stream
          if (connectorNodeLink.Type_Of_Node === 'Streamer') {
            const allRoutes = routeMap?.[connectorNodeLink?.Survey ?? 0];
            if (!allRoutes) {
              throw new Error(
                'You specified a stream in your splitter question that does not exist. Please double check game configuration to ensure its listed as one of the Stream Table Names',
              );
            }
            const streamRoutes = routeShuffler({
              allRoutes,
              pooledRoutes,
            });
            // push the first route (pre-emptively)
            // when user selects this option, then actually 'commit' the streamRoutes to the main route list proper (we override the default as well)
            setNextRoutesList(e => {
              e[linkIndex] =
                streamRoutes[0].url + WINDOW_HASH || `/${WINDOW_HASH}`;
              return e;
            });
            return () => {
              if (streamRoutes) {
                streamRoutesAction(streamRoutes, connectorNodeLink.Survey);
              } else {
                console.error(
                  `Your streamer node does not point to a valid stream name`,
                  connectorNodeLink,
                );
              }
            };
          }
        } else {
          throw new Error(
            'invalid linking structure in profile question. Links must be connectors, or nothing returned',
          );
        }
      });
    };
    setMappedControlSubmitActions(getActions());
  }, []);

  const handleLinkedSubmit = e => {
    const submittedAnswer = e;
    if (submittedAnswer && Controls?.Possible_Answers) {
      const endTimestamp = Date.now();
      dispatch(
        logItem({
          result: '',
          question_name: Name,
          question_type: Controls?.Control_Type || '',
          collection_name: 'answers',
          answer_text: submittedAnswer.controlValue.toString(),
          duration_in_seconds: startTimestamp
            ? (endTimestamp - startTimestamp) / 1000
            : 0,
        }),
      );
      // run whatever action corresponds to the input value
      // our control value needs to be offset by 1 because it won't ever give us 0 when its a likert (to make it play nice with humans and the api)
      const ans = submittedAnswer.controlValue;
      const actionIndex = (() => {
        if (typeof ans == 'boolean') {
          return submittedAnswer.controlValue ^ 0;
        }
        if (typeof ans == 'number') {
          return (submittedAnswer.controlValue - 1) ^ 0;
        }
        if (typeof ans == 'string') {
          return Controls.Possible_Answers.findIndex(
            e => e.Content_Type === ans,
          );
        }
        return 0;
      })();
      mappedControlSubmitActions[actionIndex]();
    }
  };

  useEffect(() => {
    if (
      Type_Of_Node === 'Random' &&
      nextRoutesList.length > 0 &&
      Controls?.Possible_Answers
    ) {
      // random index chosen (acts similar to when you manually select)
      const randomControlIndex = Math.floor(
        Math.random() * Controls.Possible_Answers.length,
      );
      mappedControlSubmitActions[randomControlIndex]();
      navigate(
        nextRoutesList[randomControlIndex] + WINDOW_HASH || `/${WINDOW_HASH}`,
        {
          replace: true,
        },
      );
    }
  }, [
    Controls?.Possible_Answers,
    Type_Of_Node,
    mappedControlSubmitActions,
    nextRoutesList,
  ]);

  if (Type_Of_Node === 'Random') {
    return (
      <Layout data-cy="profileQuestion">
        {backgroundSymbol && (
          <BackgroundImage
            imageData={backgroundSymbol.data}
            imageType={backgroundSymbol.type}
          />
        )}
      </Layout>
    );
  }

  return (
    <Layout data-cy="profileQuestion">
      {backgroundSymbol && (
        <BackgroundImage
          imageData={backgroundSymbol.data}
          imageType={backgroundSymbol.type}
        />
      )}
      <BodyWrapper p={5}>
        <Flex flex={0.5} />
        <Flex flexDirection="column" maxWidth="1100px" alignSelf="center">
          <Heading mb={4} variant="h2">
            <CustomMdxRenderer>
              {g(Controls?.Header_Text, true)}
            </CustomMdxRenderer>
          </Heading>
          <Para variant="p1">
            <CustomMdxRenderer>{g(Question_Text, true)}</CustomMdxRenderer>
          </Para>
        </Flex>
        <Flex mt="auto" mb={6} flexDirection="column" justifyContent="center">
          <ControlCenter
            to={nextRoutesList}
            typeOfControl={Controls?.Control_Type}
            setSubmittedAnswer={handleLinkedSubmit}
            possibleAnswers={Controls?.Possible_Answers}
          />
          {/* NOTE: escape hatch for user to just go directly to default stream */}
          {!Controls?.Is_Required && (
            <Button
              buttonSize="medium"
              variant="ghost"
              as={Link}
              onPress={() => {
                const endTimestamp = Date.now();
                dispatch(
                  logItem({
                    question_name: Name,
                    question_type: Controls?.Control_Type || '',
                    result: '',
                    collection_name: 'answers',
                    answer_text: 'skip',
                    duration_in_seconds: startTimestamp
                      ? (endTimestamp - startTimestamp) / 1000
                      : 0,
                  }),
                );
                streamRoutesAction(
                  defaultRouteList,
                  gameConfig.Stream_Table_Names[0],
                );
              }}
              mt={7}
              mx="auto"
              to={defaultRouteList[0].url + WINDOW_HASH || `/${WINDOW_HASH}`}
              replace
            >
              <Label textDecoration="underline" variant="l3">
                {t('Skip Button')}
              </Label>
            </Button>
          )}
        </Flex>
      </BodyWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ProfileQuestionPage {
    profilePageBackgroundSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export default ProfileQuestion;

export const Head = () => <Seo />;
